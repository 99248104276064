<template>
  <nav
    class="right_bg close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-w-2/12 z-10"
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
  >
    <div class="">
      <div class="flex bg-emerald-500 justify-between px-2 py-0 mneminics_mt">
        <div class="righ_side_head_">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="4"
            viewBox="0 0 12 4"
            fill="none"
          >
            <path
              d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z"
              fill="#777777"
            ></path>
          </svg>
          <p class="sidebar-heading">3D Graph</p>
        </div>
        <div>
          <svg
            v-on:click="closeNavigation"
            class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              id="close_2_"
              data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)"
              fill="#b4b5ba"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_two"
        class="flex bg-black justify-between pt-5 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ height: isExpandFeatureTwo ? '100%' : '0px' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-4 pt-2">
          <div class="flex flex-col mb-3">
            <p class="label_heading">X - Axis</p>
            <!-- ["lstk", "hklx", "tqx", "drtm"] -->
            <select
              :id="widgetId + '2'"
              class="fields border-0 block font-color"
              @change="mnemonicChangeHandler($event, 1)"
              v-model="tag2"
            >
              <option>
                {{ tag2 }}
              </option>
              <option :key="index" :value="tag" v-for="(tag, index) in tags">
                {{ tag.toUpperCase() }}
              </option>
            </select>
          </div>
          <div class="flex flex-col mb-3">
            <p class="label_heading">Y - Axis</p>
            <select
              :id="widgetId + '2'"
              class="fields border-0 block font-color"
              @change="mnemonicChangeHandler($event, 0)"
              v-model="tag1"
            >
              <option>
                {{ tag1 }}
              </option>
              <option :key="index" :value="tag" v-for="(tag, index) in tags">
                {{ tag.toUpperCase() }}
              </option>
            </select>
           
          </div>

          <div class="flex flex-col mb-3">
            <p class="label_heading">Z - Axis</p>

            <select
              :id="widgetId + '2'"
              class="fields border-0 block font-color"
              @change="mnemonicChangeHandler($event, 2)"
              v-model="tag3"
            >
              <option>
                {{ tag3 }}
              </option>
              <option :key="index" :value="tag" v-for="(tag, index) in tags">
                {{ tag.toUpperCase() }}
              </option>
              <!-- <option value="saab">Saab</option>
                  <option value="fiat">Fiat</option>
                <option value="audi">Audi</option>-->
            </select>
            <!-- <a
                class="drop_clear_btn_"
                v-if="
                  properties.mnemonicTag3.indexOf('DEFAULT') == -1 &&
                  isExpandFeatureTwo
                "
                @click="mnemonicRemoveHandler('DEFAULT2', 2)"
                >X</a 
              >-->
          </div>

          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Grid Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="changeColor($event)"
              :value="gridColor"
            />
          </div>
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Background Color</p>
            <input
              type="color"
              min="0"
              max="13"
              id="color1"
              class="h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @input="changeBackgroundColor($event)"
              :value="backgroundColor"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_four"
        class="flex justify-between pt-4 pl-5 pb-4 bg-right_"
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/range_.png" alt="" />
            Ranges
          </p>
          <!-- <p class="sidebar-label">Ranges</p> -->
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureFour"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureFour ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="">
          <div class="custom-radio-wrap mt-3 mb-4">
            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> X-Manual Scale </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                 
                  @click="toggleAxisAutoScale($event, 'zAutoScale')"
                  :checked="zAutoScale"
                />
              </label>
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">X-Start Range</p>
              <input
                type="number"
                class="fields border-0 block font-color"
                @input="changeRangeHandler($event, 'zRangeStart')"
                :value="zRangeStart"
              />
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">X-End Range</p>
              <input
                type="number"
                class="fields border-0 block font-color"
                @input="changeRangeHandler($event, 'zRangeEnd')"
                :value="zRangeEnd"
              />
            </div>

            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin mt-3">
                <span class="label_heading"> Y-Manual Scale </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleAxisAutoScale($event, 'xAutoScale')"
                  :checked="xAutoScale"
                />
              </label>
            </div>

            <div class="flex flex-col mt-3">
              <p class="label_heading">Y-Start Range</p>
              <input
                type="number"
                class="fields border-0 block font-color"
                @input="changeRangeHandler($event, 'xRangeEnd')"
                :value="xRangeEnd"
              />
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Y-End Range</p>
              <input
                type="number"
                class="fields border-0 block font-color"
                @input="changeRangeHandler($event, 'xRangeStart')"
                :value="xRangeStart"
              />
            </div>

            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin mt-3">
                <span class="label_heading"> Z-Manual Scale </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                 
                  @click="toggleAxisAutoScale($event, 'yAutoScale')"
                  :checked="yAutoScale"
                />
              </label>
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Z-Start Range</p>
              <input
                type="number"
                class="fields border-0 block font-color"
                @input="changeRangeHandler($event, 'yRangeStart')"
                :value="yRangeStart"
              />
            </div>
            <div class="flex flex-col mt-3">
              <p class="label_heading">Z-End Range</p>
              <input
                type="number"
                class="fields border-0 block font-color"
                @input="changeRangeHandler($event, 'yRangeEnd')"
                :value="yRangeEnd"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <UpDownButtons
      :isExpandFeatureSix="isExpandFeatureSix"
      :toggle_height_handler_six="toggle_height_handler_six"
      :id="id"
      :displayId="properties.displayId"
      :widgetId="properties.widgetId"
      :closePropertyFile="closeNavigation"
    />
  </nav>
</template>

<script>
import UpDownButtons from "../UpDownButtons/UpDownButtons";
import UnitSelect from "./properties/UnitSelect.vue";
export default {
  name: "3d-Graph-Properties",
  components: {
    UpDownButtons,
    UnitSelect
  },
  props: {
    displayId: String,
    widgetId: String,
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: "",
    properties: {},
    closeNavigation: Function,
  },
  data() {
    return {
      isExpandFeatureOne: false,
      isExpandFeatureTwo: true,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureSix: false,
      tag4: "",
    };
  },
  computed: {
    gridColor(){
      let rects = this.$store.state.rect.rects;
      let foundRect = rects.find(rect=> rect.widgetId == this.widgetId && rect.displayId == this.displayId)
      if(foundRect && foundRect?.gridColor){
        return foundRect.gridColor == 'white' ? '#FFFFFF' : foundRect.gridColor;
      }
      else{
        return '#FFFFFF'
      }
    },
    backgroundColor(){
      let rects = this.$store.state.rect.rects;
      let foundRect = rects.find(rect=> rect.widgetId == this.widgetId && rect.displayId == this.displayId)
      if(foundRect && foundRect?.backgroundColor){
        return foundRect.backgroundColor == 'rgb(200, 200, 230)' ? '#C8C8E6' : foundRect.backgroundColor;
      }
      else{
        return '#C8C8E6'
      }
    },
    tag1() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          return this.$store.state.rect.rects[i].mnemonicTag1.toUpperCase();
        }
      }
    },
    tag2() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          return this.$store.state.rect.rects[i].mnemonicTag2.toUpperCase();
        }
      }
    },
    tag3() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          return this.$store.state.rect.rects[i].mnemonicTag3.toUpperCase();
        }
      }
    },
    tags() {
      // this.activeDisplay = this.$store.state.data.selectedDisplay;
      let display = this.$store.state.disp.selectedDisplay;

      let displayobj = this.$store.state.disp.displays;

      if (displayobj[display]) {
        return displayobj[display].tagsTrejectory;
      } else {
        return [];
      }
      // return displayobj.tags;
    },
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    xAutoScale() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          return this.$store.state.rect.rects[i].xAutoScale;
        }
      }
    },
    yAutoScale() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          return this.$store.state.rect.rects[i].yAutoScale;
        }
      }
    },
    zAutoScale() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          return this.$store.state.rect.rects[i].zAutoScale;
        }
      }
    },
    xRangeStart() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          return this.$store.state.rect.rects[i].xRangeStart;
        }
      }
    },
    yRangeStart() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          console.log(
            "this.$store.state.rect.rects[i].yRangeStart",
            this.$store.state.rect.rects[i].yRangeStart
          );
          return this.$store.state.rect.rects[i].yRangeStart;
        }
      }
    },
    zRangeStart() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          return this.$store.state.rect.rects[i].zRangeStart;
        }
      }
    },
    xRangeEnd() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          return this.$store.state.rect.rects[i].xRangeEnd;
        }
      }
    },
    yRangeEnd() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          return this.$store.state.rect.rects[i].yRangeEnd;
        }
      }
    },
    zRangeEnd() {
      let rects = this.$store.state.rect.rects;
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.widgetId &&
          rects[i].displayId == this.displayId
        ) {
          return this.$store.state.rect.rects[i].zRangeEnd;
        }
      }
    },
  },
  methods: {
    changeColor(e) {
      this.$store.dispatch("rect/changeColor", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
      });
    },
    changeBackgroundColor(e) {
      this.$store.dispatch("rect/changeBackgroundColor", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: e.target.value,
      });
    },
    async mnemonicRemoveHandler(e, index) {
      this.$store.dispatch("rect/plotlyMnemonicChange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e,
        index,
      });
    },
    async mnemonicChangeHandler(e, index) {
      this.$store.dispatch("rect/plotlyMnemonicChange", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
        index,
      });
      // let tagValue = await api.WellServices.getDataForTags({
      //   fields: e.target.value
      // });

      // alert(JSON.stringify(tagValue.data.rows[0][e.target.value]))
      // this.$store.dispatch("rect/numericValueUpdate", {
      //   displayId: this.properties.displayId,
      //   widgetId: this.properties.widgetId,
      //   value: tagValue.data.rows[0][e.target.value]
      // });
    },
    changeRangeHandler(e, key) {
      let temp = {};
      temp[key] = e.target.value;
      this.$store.dispatch("rect/trajectoryRangeChange", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: temp,
      });
    },
    toggleAxisAutoScale(e, key) {
      let temp = {};
      temp[key] = e.target.checked;
      this.$store.dispatch("rect/trajectoryToggleAutoScale", {
        displayId: this.displayId,
        widgetId: this.widgetId,
        value: temp,
      });
    },
    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css"></style>
