<template>
  <div>
    <div
      v-on:click="toggle_height_handler_six"
      class="flex justify-between pt-5 pl-5 pb-4"
      :style="{ margin: '5px 5px', padding: '5px' }"
      :class="darkDark !== 'white' ? 'bg-right_' : 'light_setting-mode'"
    >
      <div>
        <p
          class="sidebar-label lables_with_ico"
          :style="{ fontFamily: 'Lato, sans-serif' }"
          style="font-size: 10px"
        >
          <img src="../../assets/img/settings.png" alt="" /> Settings
        </p>
      </div>
      <div class="d-flex self-center">
        <p v-if="!isExpandFeatureSix"><i class="fas fa-angle-down"></i></p>
        <p v-else><i class="fas fa-angle-up"></i></p>
      </div>
    </div>
    <div
      v-bind:style="{ display: isExpandFeatureSix ? '' : 'none' }"
      class="expand-right-sidebar-feature-one"
    >
      <div class="pb-4 pt-2">
        <div class="">
          <!-- <div class="form-group-checkbox">
            <label class="w-100 flex font-thin">
              <span class="label_heading"> Dragable </span>
              <input
                class="ml-auto leading-tight"
                type="checkbox"
                :class="darkDark ? 'input-bg' : 'input-bg_light'"
                checked
                @click="toggleDraggable"
              />
            </label>
          </div> -->
          <div class="form-group-button text-center">
            <button
              type="button"
              id="delete"
              @click="deleteWidget"
              checked
              class="bg-red-500 hover:bg-red-700 text-white font-bold px-4 py-1 rounded btn_txt"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isExpandFeatureSix: Boolean,
    toggle_height_handler_six: Function,
    id: [Number, String],
    displayId: String,
    widgetId: String,
    closePropertyFile: Function,
  },
  methods: {
    upForeground() {
      this.$store.dispatch("rect/changeZToTop", {
        displayId: this.displayId,
        widgetId: this.widgetId,
      });
    },
    downBackground() {
      this.$store.dispatch("rect/changeZToBottom", {
        displayId: this.displayId,
        widgetId: this.widgetId,
      });
    },
    toggleDraggable() {
      this.$store.dispatch("rect/toggleDraggable", {
        displayId: this.displayId,
        widgetId: this.widgetId,
      });
    },
    deleteWidget() {
      this.$store.dispatch("rect/deleteWidget", {
        displayId: this.displayId,
        widgetId: this.widgetId,
      });
      // this.$store.dispatch("disp/deleteWidget", {
      //   displayId: this.displayId,
      //   widgetId: this.widgetId,
      // });
      this.closePropertyFile();
    },
    dark() {
      // document.querySelector('nav').classList.add('dark-mode')
      // document.querySelector('div').classList.add('dark-mode')
      // document.querySelector('button').classList.add('dark-mode')
      // document.querySelector('a').classList.add('text-red-600')
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      // document.querySelector('nav').classList.remove('dark-mode')
      // document.querySelector('div').classList.remove('dark-mode')
      // document.querySelector('button').classList.remove('dark-mode')
      // document.querySelector('a').classList.remove('text-red-600')
      this.darkMode = false;
      this.$emit("light");
    },
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
  },
  watch: {
    id: async function (newVal) {
      //
    },
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css">
/* .btn_txt {
  font-size: 9px;
}
.right_bg {
  background: #1b1a1f;
}
.bg_gray {
  width: 12%;
}
.bg-right_ {
  background: #28282f;
  margin: 5px 5px;
  padding: 5px;
  border-radius: 5px;
}
.sidebar-heading {
  font-size: 9px;
  padding: 2px;
}
.sidebar-label {
  font-size: 10px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  height: 100%;
  align-items: flex-end;

}
.label_heading {
  font-size: 10px;
}
.custom-radio-wrap form .form-group-radio .label-text {
  color: white;
}

.font-color {
  color: white;
  font-size: 10px;
  padding: 0px 0px;
  height: 20px;
  width: 53%;
  text-align: center;
}

.radio_label {
  margin-left: 14px;
}
.lables_with_ico {
  display: flex;
}
.lables_with_ico > img {
  margin-right: 10px;
} */

.bg-right_ {
  background: #28282f;
}
</style>
